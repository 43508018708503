import {APP_DOWNLOAD_DIALOG_OPEN, SET_DIALOG, SET_FAVORITES, SET_FORUM_VOTES} from "../types.js";
import {localStorageIsAvailable, setCookie} from "../../library/utils.jsx";

export default function globalReducer(state, action) {
    switch (action.type) {
        case SET_FAVORITES: {
            let favourites = {
                ...state,
                favorites: action.payload
            };
            if (localStorageIsAvailable()) {
                localStorage.setItem('favourites', JSON.stringify(action.payload));
            }
            // setCookie('favourites', JSON.stringify(action.payload), 400);
            return favourites;
        }

        case SET_FORUM_VOTES: {
            let forumVotes = {
                ...state,
                forumVotes: action.payload
            };
            if (localStorageIsAvailable()) {
                localStorage.setItem('forum_votes', JSON.stringify(action.payload));
            }
            // setCookie('favourites', JSON.stringify(action.payload), 400);
            return forumVotes;
        }

        case SET_DIALOG: {
            return {
                ...state,
                dialog: {...action.payload}
            };
        }

        case APP_DOWNLOAD_DIALOG_OPEN: {
            return {
                ...state,
                downloadAppDialogOpen: action.payload
            };
        }
    }
}
