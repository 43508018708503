// import './bootstrap';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {GlobalContextProvider} from './app/contexts/GlobalContext.jsx'
import {ThemeProvider} from "@mui/material";
import defaultTheme from "./themes/defaultTheme.jsx";
import defaultThemeV2 from "./themes/defaultThemeV2.jsx";
import { betterAttribution } from "better-attribution";
import {getCookie} from "./app/library/utils.jsx";
import {isCalendlyEvent} from "./app/library/calendly.js";

const SearchBabysitters = React.lazy(() => import("./app/widgets/SearchBabysitters.jsx"));
const SearchJobs = React.lazy(() => import("./app/widgets/SearchJobs.jsx"));

const ContactUsForm = React.lazy(() => import("./app/forms/ContactUsForm.jsx"));
const ForEmployersForm = React.lazy(() => import("./app/forms/ForEmployersForm.jsx"));
const ChurchForm = React.lazy(() => import("./app/forms/ChurchForm.jsx"));
const ApplyJobForm = React.lazy(() => import("./app/forms/ApplyJobForm.jsx"));

const BabysitterDetail = React.lazy(() => import("./app/widgets/BabysitterDetail.jsx"));
const FindBabysitters = React.lazy(() => import("./app/widgets/FindBabysitters.jsx"));
const FindByZip = React.lazy(() => import("./app/widgets/FindByZip.jsx"));
const BabysitterSignUp = React.lazy(() => import("./app/widgets/sign-up/BabysitterSignUp.jsx"));
const ParentSignIn = React.lazy(() => import("./app/widgets/sign-in/ParentSignIn.jsx"));
const ParentSignUp = React.lazy(() => import("./app/widgets/sign-up/ParentSignUp.jsx"));
const GroupSignUp = React.lazy(() => import("./app/widgets/sign-up/GroupSignUp.jsx"));
const ParentSimpleSignUp = React.lazy(() => import("./app/widgets/sign-up/ParentSimpleSignUp.jsx"));

const ConnectChurchesForm = React.lazy(() => import("./app/forms/ConnectChurchesForm.jsx"));
const ConnectSchoolsForm = React.lazy(() => import("./app/forms/ConnectSchoolsForm.jsx"));
const ScheduleCallFormWidget = React.lazy(() => import("./app/forms/ScheduleCallForm.jsx"));
const BookCallFormWidget = React.lazy(() => import("./app/forms/BookCallForm.jsx"));

const SignUpSection = React.lazy(() => import("./app/sections/sign-up/SignUp.jsx"));

const Forum = React.lazy(() => import("./app/widgets/Forum.jsx"));
const BookACall = React.lazy(() => import("./app/widgets/BookACall.jsx"));

const searchBabysittersRoot = document.getElementById('search-babysitters-widget')
if (searchBabysittersRoot) {
    const root = createRoot(searchBabysittersRoot);
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultThemeV2}>
                <SearchBabysitters/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

const babysitterDetailRoot = document.getElementById('babysitter-detail-widget')
if (babysitterDetailRoot) {
    const root = createRoot(babysitterDetailRoot);
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <BabysitterDetail/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

const findBabysittersRoot = document.getElementById('find-babysitter-widget')
if (findBabysittersRoot) {
    const root = createRoot(findBabysittersRoot);
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <FindBabysitters/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

const babysitterSignUpRoot = document.getElementById('babysitter-sign-up-widget')
if (babysitterSignUpRoot) {
    const root = createRoot(babysitterSignUpRoot);
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <BabysitterSignUp/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

const parentSignUpRoot = document.getElementById('parent-sign-up-widget')
if (parentSignUpRoot) {
    const root = createRoot(parentSignUpRoot);
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <ParentSignUp/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

const groupSignUpRoot = document.getElementById('group-sign-up-widget')
if (groupSignUpRoot) {
    const root = createRoot(groupSignUpRoot);
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <GroupSignUp/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

const parentSimpleSignUpRoot = document.getElementById('parent-simple-sign-up-widget')
if (parentSimpleSignUpRoot) {
    const root = createRoot(parentSimpleSignUpRoot);
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <ParentSimpleSignUp/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

const searchJobsFilterRoot = document.getElementById('search-jobs-filter')
if (searchJobsFilterRoot) {
    const root = createRoot(searchJobsFilterRoot);
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultThemeV2}>
                <SearchJobs/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}


if (document.getElementById('contact-us-form')) {
    const root = createRoot(document.getElementById('contact-us-form'));
    root.render(
        <ThemeProvider theme={defaultThemeV2}>
            <ContactUsForm/>
        </ThemeProvider>
    );
}

if (document.getElementById('for-employers-form')) {
    const root = createRoot(document.getElementById('for-employers-form'));
    root.render(
        <ThemeProvider theme={defaultThemeV2}>
            <ForEmployersForm/>
        </ThemeProvider>
    );
}

if (document.getElementById('church-form')) {
    const root = createRoot(document.getElementById('church-form'));
    root.render(
        <ThemeProvider theme={defaultThemeV2}>
            <ChurchForm/>
        </ThemeProvider>
    );
}

if (document.getElementById('apply-job-form')) {
    const root = createRoot(document.getElementById('apply-job-form'));
    root.render(
        <ThemeProvider theme={defaultThemeV2}>
            <ApplyJobForm/>
        </ThemeProvider>
    );
}

if (document.getElementById('connect-churches-form-widget')) {
    const root = createRoot(document.getElementById('connect-churches-form-widget'));
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultThemeV2}>
                <ConnectChurchesForm/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

if (document.getElementById('connect-schools-form-widget')) {
    const root = createRoot(document.getElementById('connect-schools-form-widget'));
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultThemeV2}>
                <ConnectSchoolsForm/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

if (document.getElementById('schedule-call-form-widget')) {
    const root = createRoot(document.getElementById('schedule-call-form-widget'));
    const leadType = document.getElementById('schedule-call-form-widget').getAttribute('lead-type');
    const redirect = document.getElementById('schedule-call-form-widget').getAttribute('redirect');
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultThemeV2}>
                <ScheduleCallFormWidget leadType={leadType} redirect={redirect}/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

if (document.getElementById('book-call-form-widget')) {
    const root = createRoot(document.getElementById('book-call-form-widget'));
    const leadType = document.getElementById('book-call-form-widget').getAttribute('lead-type');
    const redirect = document.getElementById('book-call-form-widget').getAttribute('redirect');
    const referral = document.getElementById('book-call-form-widget').getAttribute('referral');
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultThemeV2}>
                <BookCallFormWidget leadType={leadType} redirect={redirect} referral={referral}/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

if (document.getElementById('parent-sign-in-widget')) {
    const root = createRoot(document.getElementById('parent-sign-in-widget'));
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <ParentSignIn/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

if (document.getElementById('sign-up-section')) {
    const root = createRoot(document.getElementById('sign-up-section'));
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultThemeV2}>
                <SignUpSection />
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

if (document.getElementById('forum-widget')) {
    const root = createRoot(document.getElementById('forum-widget'));
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <Forum/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}


const findByZipsRoots = document.querySelectorAll('.find-by-zip-widget')
if (findByZipsRoots) {
    findByZipsRoots.forEach(findByZipsRoot => {
        const root = createRoot(findByZipsRoot);
        root.render(
            <GlobalContextProvider>
                <ThemeProvider theme={defaultThemeV2}>
                    <FindByZip baseUrl={findByZipsRoot.getAttribute("base-url")} btnText={findByZipsRoot.getAttribute("btn-text")}/>
                </ThemeProvider>
            </GlobalContextProvider>
        );
    });
}

if (document.getElementById('book-a-call-widget')) {
    const root = createRoot(document.getElementById('book-a-call-widget'));
    root.render(
        <GlobalContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <BookACall/>
            </ThemeProvider>
        </GlobalContextProvider>
    );
}

// Common js.
const attr = betterAttribution();
attr.storeAttributionValues();

if (document.getElementById('schedule-call-hubspot')) {
    window.addEventListener( 'message', function(event) {
        if ( event.origin !== 'https://meetings.hubspot.com' ) {
            return false;
        }

        if ( event?.data?.meetingBookSucceeded ) {
            const formData = new FormData();
            formData.append('id', getCookie('calendly_lead_id'));
            formData.append('calendly_lead', 'true');
            formData.append('attribution_json', JSON.stringify(attr.getFirstTouch()));

            fetch(
                "/api/sign-up/parent/lead/finished-registration",
                {
                    body: formData,
                    method: "post"
                }
            );
        }

    });
}

if (document.getElementById('schedule-call-calendly')) {
    window.addEventListener("message", function(e) {
        if(isCalendlyEvent(e)) {
            if (e.data.event === 'calendly.event_scheduled') {
                const formData = new FormData();
                formData.append('id', getCookie('calendly_lead_id'));
                formData.append('calendly_lead', 'true');
                formData.append('attribution_json', JSON.stringify(attr.getFirstTouch()));

                fetch(
                    "/api/sign-up/parent/lead/finished-registration",
                    {
                        body: formData,
                        method: "post"
                    }
                );
            }
        }
    });
}
