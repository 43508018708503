import {createTheme} from "@mui/material";
import {
    COLOR_BLACK,
    COLOR_DARK_GREY,
    COLOR_DARK_GREY_V2,
    COLOR_DARKEST_GREY_V2,
    COLOR_GREEN,
    COLOR_GREEN_LIGHTEST_V2,
    COLOR_GREEN_V2,
    COLOR_LIGHT_GREEN_V2,
    COLOR_LIGHTEST_GREY_V2,
    COLOR_ORANGE,
    COLOR_RED_V2,
    COLOR_WHITE
} from "./colors.jsx";
import sharedTheme from "./sharedThemes/themeV2.jsx";

const defaultThemeV2 = createTheme({
    shared: sharedTheme,
    typography: {
        allVariants: { fontFamily: 'var(--global-font-family)' },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    width: '100%',
                    padding: '0',

                    '&:focus': {
                        borderColor: 'red',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {},
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '10px',
                    color: COLOR_DARK_GREY_V2,
                    '&.Mui-checked': {
                        color: COLOR_GREEN_V2,
                    }
                },
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    padding: '0 !important',
                },
                input: {
                    padding: '14px 16px 13px 16px !important',
                },
                listbox: {
                    maxHeight:' 20vh',
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: '0',
                    backgroundColor: COLOR_WHITE,
                    border: sharedTheme?.border?.input?.border,
                    borderRadius: sharedTheme?.radius?.regular?.borderRadius,
                    '&:hover': {
                        backgroundColor: COLOR_WHITE,
                    },
                    '&.Mui-focused': {
                        backgroundColor: COLOR_WHITE,
                    },
                    '& fieldset legend': {
                        maxWidth: '0',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none !important',
                    },
                    '&.MuiAutocomplete-inputRoot': {
                        padding: '0 65px 0 0',
                    }
                },
                input: {
                    padding: '14px 16px 13px 16px',
                    color: COLOR_BLACK,
                    border: 'none !important',
                    fontSize: sharedTheme?.text?.input?.fontSize,
                    fontWeight: sharedTheme?.text?.input?.fontWeight,
                    lineHeight: sharedTheme?.text?.input?.lineHeight,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: COLOR_DARKEST_GREY_V2,
                    fontSize: sharedTheme?.text?.input?.fontSize,
                    fontWeight: sharedTheme?.text?.input?.fontWeight,
                    lineHeight: sharedTheme?.text?.input?.lineHeight,
                    '&.Mui-focused': {
                        color: COLOR_DARKEST_GREY_V2,
                        zIndex: 2,
                    },
                },
                formControl: {
                    transform: 'translate(17px, 17px) scale(1) !important',
                },
                shrink: {
                    color: COLOR_DARKEST_GREY_V2,
                    transform: 'translate(17px, 4px) scale(0.55) !important',
                    fontWeight: '600',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    '&.Mui-focused ': {
                        color: COLOR_DARKEST_GREY_V2,
                    },
                    '&.Mui-error ': {
                        color: COLOR_RED_V2,
                    },
                    '&.Mui-focused.Mui-error ': {
                        color: COLOR_RED_V2,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    display: 'inline-block',
                    textTransform: 'none',
                    boxShadow: 'none',
                    width: '100%',
                },
                outlined: {
                    backgroundColor: sharedTheme?.button?.primary?.outlined?.regular?.backgroundColor,
                    color: sharedTheme?.button?.primary?.outlined?.regular?.color,
                    border: sharedTheme?.button?.primary?.outlined?.regular?.border,
                    fontFamily: 'var(--global-font-family)',
                    fontWeight: sharedTheme?.text?.button?.fontWeight,
                    fontSize: sharedTheme?.text?.button?.fontSize,
                    lineHeight: sharedTheme?.text?.button?.lineHeight,
                    '&:hover': {
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                        borderColor: COLOR_GREEN,
                    }
                },
                contained: {
                    backgroundColor: sharedTheme?.button?.primary?.filled?.regular?.backgroundColor,
                    color: sharedTheme?.button?.primary?.filled?.regular?.color,
                    border: sharedTheme?.button?.primary?.filled?.regular?.border,
                    fontFamily: 'var(--global-font-family)',
                    fontWeight: sharedTheme?.text?.button?.fontWeight,
                    fontSize: sharedTheme?.text?.button?.fontSize,
                    lineHeight: sharedTheme?.text?.button?.lineHeight,
                    '&:hover': {
                        backgroundColor: sharedTheme?.button?.primary?.filled?.hover?.backgroundColor,
                    },
                    '&.Mui-disabled': {
                        color: sharedTheme?.button?.primary?.filled?.disabled?.color,
                        backgroundColor: sharedTheme?.button?.primary?.filled?.disabled?.backgroundColor,
                        border: sharedTheme?.button?.primary?.filled?.disabled?.border,
                    },

                },
                sizeSmall: {
                    padding: '3px 7px',
                    borderRadius: '12px',
                    fontWeight: sharedTheme?.text?.smallSemiBold?.fontWeight,
                    fontSize: sharedTheme?.text?.smallSemiBold?.fontSize,
                    lineHeight: sharedTheme?.text?.smallSemiBold?.lineHeight,
                },
                sizeMedium: {
                    padding: '18px',
                    borderRadius: '30px',
                    minHeight: '60px',
                },
                sizeLarge: {
                    padding: '11px 15px',
                }
            }
        },
        MuiLoadingButton: {
            styleOverrides: {
                loadingIndicator: {
                    top: 16,
                },
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: '2px',
                    color: COLOR_GREEN,
                    width: 'calc(100% - 20px)',
                    marginLeft: '10px',
                    '&.Mui-disabled .MuiSlider-markLabel': {
                        color: 'rgba(0, 0, 0, 0.38)',
                    },
                },
                thumb: {
                    backgroundColor: `#beedee`,
                    boxShadow: 'none',
                    border: `1px solid ${COLOR_GREEN_V2}`,
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'rgb(189, 189, 189)',
                        boxShadow: 'none',
                    },
                    width: '24px',
                    height: '24px',
                },
                mark: {
                    width: 0,
                    height: 0,
                },
                valueLabel: {
                    backgroundColor: COLOR_GREEN_V2,
                },
                markLabel: {
                    fontSize: '1rem',
                    '&[data-index="0"]': {
                        left: '-10px !important',
                        transform: 'none',
                    },
                    '&[data-index="1"]': {
                        left: 'auto !important',
                        right: '-10px',
                        transform: 'none',
                    },
                },
                sizeSmall: {
                    height: '2px',
                    color: COLOR_DARK_GREY_V2,

                    '& .MuiSlider-track' : {
                        backgroundColor: COLOR_GREEN_V2,
                    },
                },
                thumbSizeSmall: {
                    width: '22px',
                    height: '22px',
                    backgroundColor: COLOR_GREEN_LIGHTEST_V2,
                    border: `1px solid ${COLOR_GREEN_V2}`,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'rgb(189, 189, 189)',
                        boxShadow: 'none',
                    }
                },
            },
        },
        MuiRating: {
            styleOverrides: {
                icon: {
                    '& > .MuiSvgIcon-root': {
                        width: '50px',
                        height: '50px',
                    }
                },
                iconFilled: {
                    color: COLOR_GREEN_V2
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    display: 'block',
                    color: COLOR_BLACK,
                    fontSize: sharedTheme?.text?.badge?.fontSize,
                    fontWeight: sharedTheme?.text?.badge?.fontWeight,
                    lineHeight: sharedTheme?.text?.badge?.lineHeight,
                    letterSpacing: '-0.5px',
                }
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexWrap: 'wrap',
                },
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: COLOR_BLACK,
                    border: `${sharedTheme?.border?.input?.border} !important`,
                    outline: 'none !important',
                    textTransform: 'none',
                    transition: '0.15s',
                    margin: '0 !important',
                    '&:hover': {
                        backgroundColor: `${COLOR_LIGHTEST_GREY_V2}`,
                    },
                    '&.Mui-selected': {
                        color: `${COLOR_GREEN_V2} !important`,
                        backgroundColor: `${COLOR_GREEN_LIGHTEST_V2}`,
                        borderColor: `${COLOR_GREEN_V2} !important`,
                        '&:hover': {
                            backgroundColor: `${COLOR_LIGHT_GREEN_V2}`,
                        },
                    }
                },
                sizeMedium: {
                    fontWeight: sharedTheme?.text?.button?.fontWeight,
                    fontSize: sharedTheme?.text?.button?.fontSize,
                    lineHeight: sharedTheme?.text?.button?.lineHeight,
                    height: '48px',
                    borderRadius: '24px !important',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                },
                sizeSmall: {
                    fontWeight: sharedTheme?.text?.smallSemiBold?.fontWeight,
                    fontSize: sharedTheme?.text?.smallSemiBold?.fontSize,
                    lineHeight: sharedTheme?.text?.smallSemiBold?.lineHeight,
                    height: '24px',
                    borderRadius: '12px !important',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                }
            },
        },
    },
});

export default defaultThemeV2
