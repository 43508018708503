import {createTheme} from "@mui/material";
import {
    COLOR_BORDER_GREY,
    COLOR_BORDER_LIGHT_GREY,
    COLOR_DARK_GREY,
    COLOR_GREEN,
    COLOR_GREEN_HOVER, COLOR_GREEN_V2, COLOR_ORANGE, COLOR_WHITE
} from "./colors.jsx";

const defaultTheme = createTheme({
    typography: {
        allVariants: { fontFamily: 'var(--global-font-family)' },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {},
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: COLOR_BORDER_GREY,
                    '&.Mui-checked': {
                        color: COLOR_GREEN_V2,
                    }
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: COLOR_WHITE,
                    borderRadius: '.375rem',
                    '&:hover': {
                        backgroundColor: COLOR_WHITE,
                    },
                    '&.Mui-focused': {
                        backgroundColor: COLOR_WHITE,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid ' + COLOR_BORDER_LIGHT_GREY,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid ' + COLOR_BORDER_LIGHT_GREY,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid ' + COLOR_GREEN_V2,
                    },
                    '& fieldset legend': {
                        maxWidth: '0',
                    },
                },
                input: {
                    padding: '20.5px 14px 12.5px 14px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: COLOR_DARK_GREY,
                    backgroundColor: 'transparent',
                    fontSize: '15px',
                },
                shrink: {
                    color: 'rgba(33, 37, 41,.65)',
                    transform: 'translate(14px, 5px) scale(0.80) !important',
                    '&.Mui-focused ': {
                        color: 'rgba(33, 37, 41,.65)',
                    },
                    '&.Mui-error ': {
                        color: 'rgb(211, 47, 47)',
                    },
                    '&.Mui-focused.Mui-error ': {
                        color: 'rgb(211, 47, 47)',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    display: 'inline-block',
                    textTransform: 'none',
                    boxShadow: 'none',
                    width: '100%',
                },
                outlined: {
                    borderColor: COLOR_GREEN_V2,
                    borderRadius: '22px',
                    fontFamily: 'var(--global-font-family)',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: COLOR_GREEN_V2,
                    '&:hover': {
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                        borderColor: COLOR_GREEN_V2,
                    }
                },
                contained: {
                    backgroundColor: COLOR_GREEN_V2,
                    color: '#fff',
                    borderRadius: '22px',
                    fontFamily: 'var(--global-font-family)',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    '&:hover': {
                        backgroundColor: COLOR_GREEN_HOVER,
                    },
                },
                sizeMedium: {
                    padding: '.375rem .75rem',
                },
                sizeLarge: {
                    padding: '11px 15px',
                }
            }
        },
        MuiLoadingButton: {
            styleOverrides: {
                loadingIndicator: {
                    top: 16,
                },
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: '8px',
                    color: COLOR_GREEN_V2,
                    width: 'calc(100% - 20px)',
                    marginLeft: '10px',
                    '&.Mui-disabled .MuiSlider-markLabel': {
                        color: 'rgba(0, 0, 0, 0.38)',
                    }
                },
                thumb: {
                    backgroundColor: COLOR_WHITE,
                    boxShadow: '1px 1px 5px #000',
                    '&:hover': {
                        boxShadow: 'rgba(43, 163, 166, 0.16) 0px 0px 0px 5px',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'rgb(189, 189, 189)',
                        boxShadow: 'none',
                    }
                },
                mark: {
                    width: 0,
                    height: 0,
                },
                markLabel: {
                    fontSize: '1rem',
                    '&[data-index="0"]': {
                        left: '-10px !important',
                        transform: 'none',
                    },
                    '&[data-index="1"]': {
                        left: 'auto !important',
                        right: '-10px',
                        transform: 'none',
                    },
                },
            },
        },
        MuiRating: {
            styleOverrides: {
                iconFilled: {
                    color: COLOR_ORANGE
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    display: 'block',
                    color: COLOR_DARK_GREY
                }
            }
        }

    },
});

export default defaultTheme
