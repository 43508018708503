import React, {createContext, useState, useReducer, useContext, useEffect} from 'react'
import globalReducer from "./reducers/globalReducer.jsx";
import {getCookie, hideAppDownloadDialog, hideGlobalDialog, localStorageIsAvailable} from "../library/utils.jsx";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import globalStore from "./stores/globalStore.jsx";
import {styled} from "@mui/material/styles";
import AppStore from "../components/buttons/AppStore.jsx";
import GooglePlay from "../components/buttons/GooglePlay.jsx";

// let storedFavourites = getCookie('favourites');
let storedFavourites;
if (localStorageIsAvailable()) {
    storedFavourites = localStorage.getItem('favourites');
    try {
        storedFavourites = JSON.parse(storedFavourites);
    } catch (e) {
        storedFavourites = {};
    }
}
if (!storedFavourites) {
    storedFavourites = {};
}

let storedForumVotes;
if (localStorageIsAvailable()) {
    storedForumVotes = localStorage.getItem('forum_votes');
    try {
        storedForumVotes = JSON.parse(storedForumVotes);
    } catch (e) {
        storedForumVotes = {};
    }
}
if (!storedForumVotes) {
    storedForumVotes = {};
}

const defaultValue = {
    favorites: storedFavourites,
    forumVotes: storedForumVotes,
    dialog: {
        open: false,
        title: '',
        text: '',
    },
    downloadAppDialogOpen: false,
}
export const GlobalContext = createContext(defaultValue)

export function GlobalContextProvider({children}) {
    const [state, dispatch] = useReducer(globalReducer, defaultValue);

    useEffect(() => {
        if (!globalStore.isReady) {
            globalStore.isReady = true
            globalStore.dispatch = params => dispatch(params)
            globalStore.state = state
            Object.freeze(globalStore)
        }
    }, []);

    return (
        <GlobalContext.Provider value={{ ...state, dispatch }}>
            {children}

            <Dialog
                maxWidth={'xs'}
                open={state.dialog.open}
                onClose={(event) => hideGlobalDialog()}
                disableRestoreFocus
                disableScrollLock
            >
                <DialogTitle>
                    {state.dialog.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {state.dialog.text}
                    </DialogContentText>
                    {state?.dialog?.note && <DialogNote>{state?.dialog?.note}</DialogNote>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => hideGlobalDialog()}>Close</Button>
                </DialogActions>
            </Dialog>

            <DownloadAppDialog
                open={state?.downloadAppDialogOpen}
                onClose={() => hideAppDownloadDialog()}
            >
                <Close onClick={() => hideAppDownloadDialog()}>✕</Close>
                <Desktop>
                    <DownloadAppDialogTitle>
                        Scan the QR code to download the Wyndy app
                    </DownloadAppDialogTitle>
                    <DownloadQRCode src={'/img/Wyndy-College-Babysitter-App-Download-QR-Code.svg'} />
                </Desktop>
                <Mobile>
                    <DownloadAppDialogTitle>
                        Download the Wyndy Babysitting App
                    </DownloadAppDialogTitle>
                    <DialogSubtitle>
                        Available on iOS and Android
                    </DialogSubtitle>

                    <DownloadButtons>
                        <div className="btn_item d-md-none">
                            <a target="_blank" rel="nofollow"
                               href="https://wyndy.app.link/hello">
                                <AppStoreStyled />
                            </a>
                            <a target="_blank" rel="nofollow"
                               href="https://wyndy.app.link/hello">
                                <GooglePlayStyled />
                            </a>
                        </div>
                    </DownloadButtons>

                </Mobile>
            </DownloadAppDialog>
        </GlobalContext.Provider>
    );
}

const DialogNote = styled('div')`
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    line-height: 1.2;
    font-weight: 400;
    margin-top: 10px;
`

const DownloadAppDialog = styled(Dialog)`
    z-index: 20000;

    & .MuiPaper-root {
        border-radius: ${props => props?.theme?.shared?.radius?.medium?.borderRadius || '20px'};
        padding: 20px;
        max-width: 320px;
    }
`;

const DownloadAppDialogTitle = styled('div')`
    font-size: ${props => props?.theme?.shared?.text?.title?.fontSize || '19px'};
    font-weight: ${props => props?.theme?.shared?.text?.title?.fontWeight || '600'};
    line-height: ${props => props?.theme?.shared?.text?.title?.lineHeight || '1.2'};
    margin-bottom: 20px;
    margin-right: 15px;
`

const DownloadQRCode = styled('img')`
    width: 100%;
`

const Close = styled('div')`
    position: absolute;
    top: 20px;
    right: 18px;
    font-size: 24px;
    cursor: pointer;
`

const Desktop = styled('div')`
    @media (max-width: 480px) {
        display: none;
    }
`

const Mobile = styled('div')`
    @media (min-width: 481px) {
        display: none;
    }
`

const DialogSubtitle = styled('div')`
    font-size: ${props => props?.theme?.shared?.text?.accent?.fontSize || '15px'};
    font-weight: ${props => props?.theme?.shared?.text?.accent?.fontWeight || '400'};
    line-height: ${props => props?.theme?.shared?.text?.accent?.lineHeight || '1.2'};
    margin-bottom: 20px;
`

const DownloadButtons = styled('div')`
    display: flex;
    flex-direction: row;
`

const AppStoreStyled = styled(AppStore)`
    width: 100%;
    margin-bottom: 10px;
`


const GooglePlayStyled = styled(GooglePlay)`
    width: 100%;
    margin-bottom: 10px;
`
