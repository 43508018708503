export const COLOR_GREEN = '#2ba3a6';
export const COLOR_GREEN_HOVER = '#197071';
export const COLOR_DARK_GREY = '#212529';
export const COLOR_MIDDLE_GREY = '#7f7f7f';
export const COLOR_BORDER_GREY = '#999';
export const CONTROL_BORDER = '#d2d2d2';
export const COLOR_BORDER_LIGHT_GREY = '#dee2e6';
export const COLOR_LIGHT_GREY = '#dee2e6';
export const COLOR_WHITE = '#fff';
export const COLOR_ORANGE = '#f79c72';
export const COLOR_BLACK = '#000';
export const COLOR_RED = '#ff2b2b';
export const COLOR_PINK = '#ffbbbb';
export const COLOR_YELLOW = '#ffca60';
export const COLOR_BLUE = '#0d6efd';

/*
 * New Design Colors
 */

export const COLOR_GREEN_V2 = '#00C0C1';
export const COLOR_GREEN_DARK_V2 = '#00A5A7';
export const COLOR_GREEN_LIGHTEST_V2 = '#d9f6f6';
export const COLOR_GREEN_HOVER_V2 = '#01acad';
export const COLOR_LIGHT_GREEN_V2 = '#ccf2f3';
export const COLOR_LIGHTEST_GREY_V2 = '#fbfbfb';
export const COLOR_LIGHT_GREY_V2 = '#f2f2f2';
export const COLOR_GREY_V2 = '#e5e5e5';
export const COLOR_DARK_GREY_V2 = '#cccccc';
export const COLOR_DARKER_GREY_V2 = '#9a9a9a';
export const COLOR_DARKEST_GREY_V2 = '#7f7f7f';
export const COLOR_DARK_BLUE_V2 = '#11395B';
export const COLOR_DARK_BLUE_HOVER_V2 = '#0e2f4b';
export const COLOR_LIGHT_BLUE_V2 = '#cfd7de';
export const COLOR_RED_V2 = '#f00';

export const COLOR_ORANGE_V2 = '#FFDAB7';
export const COLOR_DARK_ORANGE_V2 = '#D9632C';
