import {
    COLOR_BLACK, COLOR_DARK_BLUE_HOVER_V2, COLOR_DARK_BLUE_V2,
    COLOR_DARK_GREY_V2, COLOR_GREEN_HOVER_V2,
    COLOR_GREEN_V2,
    COLOR_GREY_V2, COLOR_LIGHT_BLUE_V2, COLOR_LIGHT_GREEN_V2,
    COLOR_LIGHT_GREY_V2,
    COLOR_WHITE
} from "../colors.jsx";

const styles = {
    text: {
        giganormous: {
            fontSize: '54px',
            fontWeight: '700',
            lineHeight: '1.2',
        },
        massive: {
            fontSize: '44px',
            fontWeight: '700',
            lineHeight: '1.2',
        },
        large: {
            fontSize: '34px',
            fontWeight: '700',
            lineHeight: '1.2',
        },
        header: {
            fontSize: '21px',
            fontWeight: '700',
            lineHeight: '1.2',
        },
        headerThin: {
            fontSize: '21px',
            fontWeight: '400',
            lineHeight: '1.2',
        },
        title: {
            fontSize: '19px',
            fontWeight: '600',
            lineHeight: '1.2',
        },
        subtitle: {
            fontSize: '19px',
            fontWeight: '400',
            lineHeight: '1.2',
        },
        button: {
            fontSize: '19px',
            fontWeight: '600',
            lineHeight: '1.2',
        },
        regular: {
            fontSize: '17px',
            fontWeight: '400',
            lineHeight: '1.2',
        },
        badge: {
            fontSize: '17px',
            fontWeight: '600',
            lineHeight: '1.2',
        },
        input: {
            fontSize: '19px',
            fontWeight: '200',
            lineHeight: '1.2',
        },
        accent: {
            fontSize: '15px',
            fontWeight: '400',
            lineHeight: '1.2',
        },
        accentBold: {
            fontSize: '15px',
            fontWeight: '700',
            lineHeight: '1.2',
        },
        small: {
            fontSize: '13px',
            fontWeight: '400',
            lineHeight: '1.2',
        },
        smallSemiBold: {
            fontSize: '13px',
            fontWeight: '600',
            lineHeight: '1.2',
        },
        smallBold: {
            fontSize: '13px',
            fontWeight: '700',
            lineHeight: '1.2',
        },
        tiny: {
            fontSize: '11px',
            fontWeight: '600',
            lineHeight: '1.2',
        },
    },
    border: {
        regular: {
            border: `1px solid ${COLOR_LIGHT_GREY_V2}`,
        },
        dividing: {
            border: `1px solid ${COLOR_GREY_V2}`,
        },
        input: {
            border: `1px solid ${COLOR_DARK_GREY_V2}`,
        },
        smallDashed: {
            border: `1px dashed ${COLOR_BLACK}`,
        },
        bigDashed: {
            border: `2px dashed ${COLOR_BLACK}`,
        }
    },
    radius: {
        regular: {
            borderRadius: '12px',
        },
        medium: {
            borderRadius: '20px',
        }
    },
    button: {
        primary: {
            filled: {
                regular: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_DARK_BLUE_V2,
                    border: `1px solid ${COLOR_DARK_BLUE_V2}`,
                },
                disabled: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_LIGHT_BLUE_V2,
                    border: `1px solid ${COLOR_LIGHT_BLUE_V2}`,
                },
                hover: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_DARK_BLUE_HOVER_V2,
                    border: `1px solid ${COLOR_DARK_BLUE_HOVER_V2}`,
                }
            },
            outlined: {
                regular: {
                    color: COLOR_DARK_BLUE_V2,
                    backgroundColor: COLOR_WHITE,
                    border: `1px solid ${COLOR_DARK_BLUE_V2}`,
                },
                disabled: {
                    color: COLOR_LIGHT_BLUE_V2,
                    backgroundColor: COLOR_WHITE,
                    border: `1px solid ${COLOR_LIGHT_BLUE_V2}`,
                },
                hover: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_DARK_BLUE_V2,
                    border: `1px solid ${COLOR_DARK_BLUE_V2}`,
                },
            },
            dashed: {
                regular: {
                    color: COLOR_DARK_BLUE_V2,
                    backgroundColor: COLOR_WHITE,
                    border: `1px dashed ${COLOR_DARK_BLUE_V2}`,
                },
                disabled: {
                    color: COLOR_LIGHT_BLUE_V2,
                    backgroundColor: COLOR_WHITE,
                    border: `1px dashed ${COLOR_LIGHT_BLUE_V2}`,
                },
                hover: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_DARK_BLUE_V2,
                    border: `1px solid ${COLOR_DARK_BLUE_V2}`,
                },
            },
        },
        secondary: {
            filled: {
                regular: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_GREEN_V2,
                    border: `1px solid ${COLOR_GREEN_V2}`,
                },
                disabled: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_LIGHT_GREEN_V2,
                    border: `1px solid ${COLOR_LIGHT_GREEN_V2}`,
                },
                hover: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_GREEN_HOVER_V2,
                    border: `1px solid ${COLOR_GREEN_HOVER_V2}`,
                },
            },
            outlined: {
                regular: {
                    color: COLOR_GREEN_V2,
                    backgroundColor: COLOR_WHITE,
                    border: `1px solid ${COLOR_GREEN_V2}`,
                },
                disabled: {
                    color: COLOR_LIGHT_GREEN_V2,
                    backgroundColor: COLOR_WHITE,
                    border: `1px solid ${COLOR_LIGHT_GREEN_V2}`,
                },
                hover: {
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_GREEN_V2,
                    border: `1px solid ${COLOR_GREEN_V2}`,
                },
            },
        }
    },
    link: {
        regular: {
            color: COLOR_GREEN_V2,
        },
        hover: {
            color: COLOR_GREEN_HOVER_V2,
        }
    },
    error: {
        blink: false,
    },
    skeleton: {
        input: {
            height: '55px',
            marginBottom: '0px',
        }
    }
}

export default styles;
