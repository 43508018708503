import {APP_DOWNLOAD_DIALOG_OPEN, SET_DIALOG, SET_FAVORITES, SET_FORUM_VOTES} from "../types.js";

export const setFavorites = value => ({
    type: SET_FAVORITES,
    payload: value,
});

export const setForumVotes = value => ({
    type: SET_FORUM_VOTES,
    payload: value,
});

export const setDialog = value => ({
    type: SET_DIALOG,
    payload: value,
});

export const setAppDownloadDialogOpen = value => ({
    type: APP_DOWNLOAD_DIALOG_OPEN,
    payload: value,
});
