export function initCalendly(didLoad) {
    const tag = document.createElement('script');
    tag.async = true;
    tag.src = 'https://assets.calendly.com/assets/external/widget.js';
    tag.onload = () => {
        didLoad && didLoad();
    }
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(tag);

    const head = document.head;
    const link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = 'https://assets.calendly.com/assets/external/widget.css';
    head.appendChild(link);
}

export function isCalendlyEvent(e) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
}
