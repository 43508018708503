import globalStore from "../contexts/stores/globalStore.jsx";
import {setAppDownloadDialogOpen, setDialog} from "../contexts/actions/global.jsx";
import dayjs from "dayjs";

let useLocalStorage = null;

export function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
export function eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function showGlobalDialog(title = '', text = '', note = '')
{
    globalStore.dispatch(setDialog({
        open: true,
        title,
        text,
        note
    }))
}

export function hideGlobalDialog()
{
    globalStore.dispatch(setDialog({
        open: false,
        title: '',
        text: '',
    }))
}

export function showAppDownloadDialog()
{
    globalStore.dispatch(setAppDownloadDialogOpen(true))
}

export function hideAppDownloadDialog()
{
    globalStore.dispatch(setAppDownloadDialogOpen(false))
}

export function showRequestErrorDialog() {
    showGlobalDialog(
        'Request Error',
        'Error while sending request to server'
    );
}

export function showRequestErrorDialogWithNote(note) {
    showGlobalDialog(
        'Request Error',
        'Error while sending request to server',
        note
    );
}

export const localStorageIsAvailable = function () {
    if (useLocalStorage !== null) {
        return useLocalStorage;
    }

    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        useLocalStorage = true;
        return true;
    } catch (e) {
        useLocalStorage = false;
        return false;
    }
};

export const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
}

export const isObjectNotEmpty = (objectName) => {
    return !isObjectEmpty(objectName)
}

export const areObjectsEqual = (obj1, obj2) => {
    // Check if both arguments are null
    if (obj1 === null && obj2 === null) {
        return true;
    }

    // Check if only one argument is null
    if (obj1 === null || obj2 === null) {
        return false;
    }

    // Check if both arguments are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 === obj2;
    }

    // Get the keys of each object
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if the values of each key are equal
    for (let key of keys1) {
        // Recursively check nested objects
        if (!areObjectsEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export const phoneNumberOnlyDigits = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


export function parseToUtcDate(date, format) {
    const parsedDate = dayjs(date, format);
    return new Date(Date.UTC(parsedDate.year(), parsedDate.month(), parsedDate.date(), parsedDate.hour(), parsedDate.minute(), parsedDate.second()));
}


export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export async function convertToJpeg(source) {
    let image = await createImageBitmap(source);

    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;

    let context = canvas.getContext("2d");
    context.drawImage(image, 0, 0);

    let result = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob != null) {
                resolve(blob);
            } else {
                reject(new Error("Failed to convert file"));
            }
        }, 'image/jpeg', 1);
    });

    image.close();
    return result;
}

export function amPmAddHours(time, hours) {
    const date = dayjs(time, 'hh:mm A');
    const newDate = date.add(hours, 'hour');
    return newDate.format('h:mm A');
}

export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export function kindOfCareByUrl() {
    if (window.location.pathname.includes('nannies')) {
        return 'nanny';
    }
    if (window.location.pathname.includes('babysitters')) {
        return 'babysitter';
    }
    if (window.location.pathname.includes('childcare')) {
        return 'childcare';
    }
    return '';
}

export function isValidZipCode(zipCode) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
}
